<template>
  <!-- eslint-disable max-len -->
  <div class="el-body profile">
    <ep-layout />
    <el-aside-menu :isOpen="asideMenusIsOpened">
      <!-- top part without scroll -->
      <el-aside-menu-sticky>
        <el-aside-menu-title v-if="user">
          <span class="icon-espace_perso"></span>&nbsp;
          <span>{{ user.firstname }} {{ user.lastname }}</span>
        </el-aside-menu-title>
      </el-aside-menu-sticky>

      <el-aside-menu-tab
        v-for="option in options"
        :class="{ active: option.text === selected }"
        :key="option.text"
        @click.native="selected = option.text"
        tabindex="0"
        :aria-label="option.text"
      >
        <span v-if="option.text !== $t('profile.accord') || Status.canSelectAgreement">
          <span :class="option.icon"></span>
          {{ option.text }}
          <span :class="['ep-badge-indicator', { active: Status.hasBPE }]" v-if="option.text === $t('profile.accord')"></span>
        </span>
      </el-aside-menu-tab>
    </el-aside-menu>
    <div class="el-panel el-panel--aside-menu">
      <el-toolbar :sticky="true">
        <h2 class="el-main-title">{{ selected }}</h2>

        <el-toolbar-spacer />
      </el-toolbar>

      <div class="el-dialog" v-if="this.franceConnectClbDatas !== undefined">
        <div class="el-dialog__container">
          <div class="el-panel">
            <h3 class="el-title">Vérification de vos informations FranceConnect</h3>
            <br />
            <div v-if="this.franceConnectClbDatas.subRegistered === undefined">
              <p>Vos informations d'identification sont en cours de vérification</p>
              <div class="el-loader el-loader__small">
                <span class="el-loader--dot"></span>
                <span class="el-loader--dot"></span>
                <span class="el-loader--dot"></span>
              </div>
            </div>
            <div v-if="this.franceConnectClbDatas.subRegistered">
              <p>
                Vos informations d'identification FranceConnect ont été enregistrées avec succès. <br />
                Vous pouvez dorénavant vous connecter à votre compte eDocPerso en utilisant l'identification FranceConnect
                <br /><br /><br />
              </p>
            </div>
            <div v-else-if="this.franceConnectClbDatas.subRegistered === false">
              <p>
                Vos informations d'identification FranceConnect n'ont pu être vérifiées. <br />
                Veuillez réessayer d'ici quelques minutes.
              </p>
            </div>
          </div>
          <el-toolbar :bottom="true">
            <button class="el-button el-button--inverted" @click="franceConnectClbDatas = undefined">
              {{ $t('button.close') }}
            </button>
          </el-toolbar>
        </div>
      </div>

      <EmailDomainDialog
        v-if="emailDomainDialogOpened"
        :mail="userData.email"
        @close="emailDomainDialogOpened = false"
        @sendValidationCode="sendEmailValidationCode(); emailDomainDialogOpened = false"
      />

      <div v-if="selected === $t('profile.parameters')">
        <el-expansion-panel ref="info" :text="$t('profile.information')">
          <form @submit.prevent="updateUserInfo()">
            <div
              v-for="field in Info"
              :key="field.type"
              :class="{ 'ep-input-group--small-size': field.type !== 'address', 'ep-input-group': field.type === 'address' }"
            >
              <label class="el-input-group__label">{{ field.label }}</label>
              <input
                class="el-input-group__input"
                required="required"
                type="text"
                v-model="userData[field.type]"
                :placeholder="field.placeholder"
                :pattern="field.pattern"
                :readonly="field.readonly"
              />
            </div>
            <div class="ep-button--panel-bottom">
              <button type="submit" v-if="selected === $t('profile.parameters')" class="el-button">{{ $t('profile.save') }}</button>

              <button type="button" v-if="selected === $t('profile.parameters')" class="el-button el-button--inverted" @click.prevent="cancelInfo()">
                {{ $t('profile.cancel') }}
              </button>
            </div>
          </form>
        </el-expansion-panel>
        <el-expansion-panel ref="panelPass" :text="$t('profile.password')" v-if="!this.userData.isLinkedToFranceConnect">
          <form @submit.prevent="">
            <div v-for="field in Pass" :key="field.type" class="ep-input-group--small-size">
              <label class="el-input-group__label">{{ field.label }}</label>
              <input
                :ref="field.type"
                v-show="field.type !== ''"
                class="el-input-group__input"
                :required="field.type !== ''"
                :placeholder="field.placeholder"
                type="password"
                pattern=".{8,}"
              />
            </div>
            <div class="ep-input-group--small-size align-top">
              <label class="el-input-group__label">{{ $t('profile.new') }}</label>
              <input
                ref="newPassword"
                name="newPassword"
                class="el-input-group__input"
                v-model="password.newPassword"
                :placeholder="$t('profile.new.placeholder')"
                @input="noCustomValidityMessage"
                type="password"
              />
              <password
                strengthMeterClass="ep-strength-meter"
                v-model="password.newPassword"
                :strength-meter-only="true"
                :secureLength="8"
                @score="showScore"
                @feedback="showFeedback"
              />
              <el-toolbar>
                <el-toolbar-spacer />
                <div>
                  <span class="align-top" :class="classObject" v-html="strength"></span>
                  <span v-if="score !== null && score >= 0 && score < 3" class="ep-tooltip" :class="classObject" :data="getTooltipText()">
                    <span class="icon-help_outline"></span>
                  </span>
                </div>
              </el-toolbar>
            </div>
            <div class="ep-input-group--small-size align-top">
              <label class="el-input-group__label">{{ $t('profile.current.confirm') }}</label>
              <input
                ref="passConf"
                :class="{ 'el-input-group__input': true, 'el-input-group__input--invalid': match === false }"
                :placeholder="confirm"
                required
                v-model="password.similar"
                @input="noCustomValidityMessage"
                @keyup="check()"
                type="password"
              />
            </div>
            <div class="ep-button--panel-bottom">
              <button type="submit" v-if="selected === $t('profile.parameters')" class="el-button" @click="updatePass()">
                {{ $t('profile.save') }}
              </button>

              <button v-if="selected === $t('profile.parameters')" class="el-button el-button--inverted" @click="cancelPass()" type="button">
                {{ $t('profile.cancel') }}
              </button>
            </div>
          </form>
        </el-expansion-panel>
        <el-expansion-panel ref="panelFranceConnect" :text="$t('profile.franceconnect')">
          <div style="height: 240px">
            <div>
              FranceConnect est la solution proposée par l’État pour sécuriser et simplifier la connexion à vos services en ligne.<br /><br />
            </div>
            <div v-if="!this.userData.isLinkedToFranceConnect">
              Vous pouvez activer la connexion FranceConnect afin de vous connecter à votre compte eDocPerso par le biais de vos identifiants Ameli ou
              impots.gouv.
              <br />
              Pour se faire, identifiez-vous en cliquant sur le bouton ci-dessous.
              <br />
              <button class="mt-2 franceConnect-button" @click="redirectToFranceConnectAuth"></button><br />
              <a href="https://app.franceconnect.gouv.fr/en-savoir-plus" style="color: #0b6ba8; text-decoration: underline" target="_blank">
                Qu'est-ce que FranceConnect ?
              </a>
              <br /><br />
            </div>
            <div v-if="this.userData.isLinkedToFranceConnect">
              Votre connexion FranceConnect est active.<br /><br />
              <div class="ep-button--panel-bottom">
                <button class="el-button el-button" @click="disableFranceConnectLink">
                  {{ $t('button.disable') }}
                </button>
              </div>
            </div>
          </div>
        </el-expansion-panel>
        <el-expansion-panel :text="$t('profile.adminDocs')">
          <el-card v-for="(doc, index) in Docs" :key="doc.type">
            <el-card-icon :class="doc.icon" v-if="doc.typeCode !== 'TSMT'" />
            <div v-else class="text-center">
              <img src="../../assets/img/icon_will.svg" alt="will" />
            </div>
            <b>
              {{ doc.text.slice(0, 18) }}
              <span v-if="doc.text.length > 18">...</span>
            </b>
            <button class="el-button el-button--small" v-if="doc.file !== null" @click="CheckinDocs(doc.file)">{{ $t('profile.docs.check') }}</button>

            <label class="el-button el-button--small ep-input-group--center" v-if="doc.file === null">
              <input
                type="file"
                ref="file"
                accept=".pdf"
                @change.stop="handleFileUpload(doc.typeDescription, doc.typeCode)"
                class="ep-input-group--upload"
              />
              {{ $t('profile.docs.import') }}
            </label>
            <el-card-hover-panel v-if="doc.file !== null">
              <el-card-hover-button
                class="icon-supprime"
                @click.native="deleteFile(doc.file.id,index)"
              />
            </el-card-hover-panel>
          </el-card>
        </el-expansion-panel>

        <!--modal update user -->
        <div class="el-dialog" v-show='modalVisible'>
          <div class="el-dialog__container">
            <div class="el-panel">
              <div @click="showModal" class="ep-dialog__icon">x</div>
              <form v-if="mode.validationEmailMode" v-on:submit.prevent="verifyEmailValidationCode()">
                <h2 className="el-title" class="mt-4">{{$t('profile.validation.mail.code.title')}}</h2>
                <div class="mt-4 mb-4">
                  <label class="el-input-group__label">{{$t('profile.validation.mail.code.label')}}</label>
                  <input
                    v-model="verificationMailCode"
                    class="el-input-group__input"
                    required
                    :placeholder="$t('profile.validation.mail.code.placeholder')"
                  />
                </div>
                <button
                  class="el-toolbar el-button el-toolbar--bottom "
                >{{ $t('button.validate')}}</button>
              </form>
              <form v-else v-on:submit.prevent="verifyPassword()">
                <h2 className="el-title" class="mt-4">{{$t('profile.password.modal.title')}}</h2>
                <div class="mt-4 mb-4">
                  <label class="el-input-group__label">{{$t('profile.current.placeholder')}}</label>
                  <input
                    v-model="verifPass"
                    class="el-input-group__input"
                    required
                    :placeholder="$t('profile.current.placeholder')"
                    type="password"
                  />
                </div>
                <button
                  class="el-toolbar el-button el-toolbar--bottom "
                >{{ $t('button.validate')}}</button>
              </form>
            </div>
          </div>
        </div>

      </div>
      <div v-if="selected === $t('profile.services')">
        <el-expansion-panel :text="Service[0]">
          <table class="el-table">
            <thead>
              <tr>
                <th class="ep-table__head ep-table__head--null ep-table__head--main">{{ $t('profile.services.site') }}</th>
                <th class="ep-table__head ep-table__head--null ep-table__head--main">{{ $t('profile.services.username') }}</th>
                <th class="ep-table__head ep-table__head--null ep-table__head--main">{{ $t('profile.services.password') }}</th>
              </tr>
            </thead>
            <tbody class="ep-table__body">
              <tr v-for="(service, index) in services" :key="index" class="el-table__row">
                <td v-if="!showState[index]" class="ep-table__cell ep-table__cell--null ep-table__cell--main">{{ service.siteName }}</td>
                <td class="ep-table__cell ep-table__cell--null ep-table__cell--main" v-else>
                  <div>
                    <input
                      class="ep-table__cell ep-table__cell--null ep-table__cell--input"
                      ref="siteName"
                      placeholder="Please enter site name "
                      :value="service.siteName"
                    />
                    &nbsp;
                  </div>
                </td>

                <td v-if="!showState[index]" class="ep-table__cell ep-table__cell--null ep-table__cell--main">{{ service.login }}</td>
                <td class="ep-table__cell ep-table__cell--null ep-table__cell--main" v-else>
                  <div>
                    <input
                      class="ep-table__cell ep-table__cell--null ep-table__cell--input"
                      ref="login"
                      placeholder="Please enter login"
                      :value="service.login"
                    />
                    &nbsp;
                  </div>
                </td>
                <td v-if="!showState[index]" class="ep-table__cell ep-table__cell--null ep-table__cell--main">
                  <span v-if="revealState[index]">
                    {{ service.password }}
                    <button @click="reveal(index)" class="el-rectangle-button">{{ $t('profile.services.hide') }}</button>
                  </span>
                  <button v-else class="el-rectangle-button" @click="reveal(index)">{{ $t('profile.services.reveal') }}</button>
                </td>
                <td class="ep-table__cell ep-table__cell--null ep-table__cell--main" v-else>
                  <div>
                    <input
                      class="ep-table__cell ep-table__cell--null ep-table__cell--input"
                      ref="password"
                      type="text"
                      :value="service.password"
                      placeholder="Please enter password"
                    />
                    &nbsp;
                  </div>
                </td>
                <td class="ep-table__cell ep-table__cell--null ep-table__cell--main">
                  <!-- <span v-if="revealState[index]">
                Site
                <button @click="reveal(index)" class="el-rectangle-button">hide</button>
              </span>
                <button v-else class="el-rectangle-button" @click="reveal(index)">reveal</button>-->
                </td>
                <td class="ep-table__cell ep-table__cell--button">
                  <button @click="show(index)" v-if="!showState[index]" class="el-rectangle-button">
                    <span class="icon-edit"></span>
                  </button>
                  <button @click.stop="deleteSer(service.id, index)" v-if="!showState[index]" class="el-rectangle-button">
                    <span class="icon-supprime"></span>
                  </button>
                  <button @click.stop="updateSer(service.id, index)" v-if="showState[index]" class="el-rectangle-button">
                    <span class="icon-save"></span>
                  </button>
                  <button @click="hide(index)" v-if="showState[index]" class="el-rectangle-button">
                    <span class="icon-close"></span>
                  </button>
                </td>
              </tr>
              <tr v-if="add">
                <td colspan="2" class="el-table__cell el-table__cell--button">
                  <button @click="add = false" class="el-button el-button--small">+</button>
                </td>
              </tr>

              <tr v-show="add === false" class="el-table__row">
                <td :main="true" class="el-table__cell el-table__cell--null el-table__cell--main">
                  <div>
                    <input
                      class="ep-table__cell ep-table__cell--null ep-table__cell--input"
                      type="text"
                      required
                      v-model="site"
                      :placeholder="servPlaceholder[0]"
                    />
                    &nbsp;
                  </div>
                  <p v-if="warn" class="ep-warning">{{ $t('profile.services.required') }}</p>
                </td>
                <td :main="true" class="el-table__cell el-table__cell--null el-table__cell--main">
                  <div>
                    <input
                      class="ep-table__cell ep-table__cell--null ep-table__cell--input"
                      type="text"
                      required
                      v-model="log"
                      :placeholder="servPlaceholder[1]"
                    />
                    &nbsp;
                  </div>
                  <p v-if="warn" class="ep-warning">{{ $t('profile.services.required') }}</p>
                </td>
                <td :main="true" class="el-table__cell el-table__cell--null el-table__cell--main">
                  <div>
                    <input
                      v-model="pass"
                      class="ep-table__cell ep-table__cell--null ep-table__cell--input"
                      type="text"
                      required
                      :placeholder="servPlaceholder[2]"
                    />
                    &nbsp;
                  </div>
                  <p v-if="warn" class="ep-warning">{{ $t('profile.services.required') }}</p>
                </td>
                <td class="ep-special">
                  <button @click="saveServ()" class="el-button" type="submit">
                    <div class="icon-completed"></div>
                  </button>
                  <button @click="add = true" class="el-button el-button--inverted">
                    <div class="icon-close"></div>
                  </button>
                </td>
              </tr>
            </tbody>
            <tfoot></tfoot>
          </table>
        </el-expansion-panel>
        <el-expansion-panel v-if="!isMIPIH" ref="Code" :text="Service[1]">
          <br />
          <br />
          {{ $t('services.retrieve.text') }}
          <br />
          <br />
          <br />
          <label class="el-input-group__label">{{ $t('services.retrieve.phone') }}</label>
          <div class="ep-input-group--sign">
            <input class="el-input-group__input" v-model="mobile" :placeholder="servPlaceholder[4]" />
            <br />
          </div>

          <el-toolbar :sticky="true">
            <el-toolbar-spacer />
            <button class="el-button" @click="retrieveCode()">{{ $t('service.validate') }}</button>
          </el-toolbar>
        </el-expansion-panel>
        <el-expansion-panel ref="export" :text="$t('profile.digitalSafe')">
          <p class="el-text" v-html="$t('profile.export.text')"></p>
          <br />
          <el-toolbar :sticky="true">
            <el-toolbar-spacer />
            <button class="el-button" @click="exportSafebox()">
              {{ $t('profile.digitalSafe') }}
            </button>
          </el-toolbar>
        </el-expansion-panel>

        <!-- <div class="el-toolbar">
          <div class="el-toolbar__spacer"></div>
          <button class="el-button">+</button>
          <div class="el-toolbar__spacer"></div>
        </div>-->
      </div>
      <div v-if="selected === $t('profile.accord') && Status.canSelectAgreement">
        <br />
        <br />
        <template v-if="Status.hasBPE">{{ $t('profile.accord.dematerialization.deactivate') }}</template>
        <template v-else>{{ $t('profile.accord.dematerialization.activate') }}</template>
        <br />
        <br />
        <br />
        <label class="el-input-group__label">{{ $t('profile.signatureCode') }}</label>
        <div class="ep-input-group--sign">
          <input class="el-input-group__input" v-model="Signature" :placeholder="servPlaceholder[3]" />
          <br />

          <!-- <p class="ep-warning" v-if="Signature.length === 0">{{ $t('profile.services.required')}}</p> -->
        </div>

        <p class="el-link" @click="forgotten">{{ $t('profile.signatureCode.forgotten') }}</p>

        <el-toolbar :sticky="true">
          <el-toolbar-spacer />
          <button v-if="!Status.hasBPE" class="el-button" @click="activate()">{{ $t('profile.activate') }}</button>
          <button v-else class="el-button" :disabled="Signature.length === 0" @click="desactivate()">
            {{ $t('profile.desactivate') }}
          </button>
        </el-toolbar>
      </div>
      <div v-else-if="selected === $t('profile.accord') && !Status.canSelectAgreement">
        <br />
        <br />
        <br />
        {{ $t('profile.rights') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import Password from 'vue-password-strength-meter';
import { uuid } from 'vue-uuid';
import EpLayout from '@/layouts/Default.vue';
import trackingServices from '@/plugins/posthog';
import auth from '../../utils/auth';
import popularEmailDomains from '../../utils/mailDomains.json';
import EmailDomainDialog from '../../components/dialog/EmailDomainDialog.vue';

export default {
  name: 'profile-index',
  components: {
    EpLayout,
    Password,
    EmailDomainDialog,
  },
  props: {
    isFcCallback: {
      type: Boolean,
    },
    isFranceConnectOpened: {
      type: Boolean,
    },
  },

  data() {
    return {
      score: null,
      feedback: {
        warning: '',
        suggestions: [],
      },
      mobile: null,
      n: null,
      AdminFolder: {},
      file: null,
      warn: false,
      Signature: '',
      site: null,
      log: null,
      pass: null,
      servPlaceholder: [
        this.$i18n.t('profile.services.site.placeholder'),
        this.$i18n.t('profile.services.username.placeholder'),
        this.$i18n.t('profile.services.password.placeholder'),
        this.$i18n.t('profile.signatureCode'),
        this.$i18n.t('services.retrieve.phone.placeholder'),
      ],
      add: true,
      revealState: [],
      showState: [],
      adding: false,
      match: null,
      password: {
        newPassword: '',
        similar: '',
      },
      franceConnectClbDatas: undefined,
      selected: '',
      confirm: this.$i18n.t('profile.current.confirm.placeholder'),
      options: [
        {
          text: this.$i18n.t('profile.parameters'),
          icon: 'icon-setting',
          show: true,
        },
        {
          text: this.$i18n.t('profile.services'),
          icon: 'icon-services',
          show: true,
        },
        {
          text: this.$i18n.t('profile.accord'),
          icon: 'icon-brouillon',
          show: true,
        },
      ],

      showInput: false,
      Info: [
        {
          label: this.$i18n.t('profile.fname'),
          type: 'firstname',
          placeholder: this.$i18n.t('profile.fname.placeholder'),
          readonly: false,
        },
        {
          label: this.$i18n.t('profile.lname'),
          type: 'lastname',
          placeholder: this.$i18n.t('profile.lname.placeholder'),
          readonly: false,
        },
        {
          label: this.$i18n.t('profile.Email'),
          type: 'email',
          placeholder: this.$i18n.t('profile.Email.placeholder'),
        },
        {
          label: this.$i18n.t('profile.phone'),
          type: 'phone',
          placeholder: this.$i18n.t('profile.phone.placeholder'),
          pattern: '[0-9]{10,11}',
        },
        {
          label: this.$i18n.t('profile.address'),
          type: 'address',
          placeholder: this.$i18n.t('profile.address.placeholder'),
        },
        {
          label: this.$i18n.t('profile.zip'),
          type: 'zipCode',
          placeholder: this.$i18n.t('profile.zip.placeholder'),
          pattern: '[0-9]{5}',
        },
        {
          label: this.$i18n.t('profile.City'),
          type: 'town',
          placeholder: this.$i18n.t('profile.City.placeholder'),
        },
      ],
      Service: [this.$i18n.t('service.login'), this.$i18n.t('service.retrieveCode')],

      Pass: [
        {
          label: this.$i18n.t('profile.current'),
          type: 'actualPassword',
          placeholder: this.$i18n.t('profile.current.placeholder'),
        },
        {
          label: '',
          type: '',
          placeholder: '',
        },
      ],
      verifPass: '',
      verifEmail: '',
      modalVisible: false,
      mode: {
        validationEmailMode: false,
        validationPasswordMode: false,
      },
      verificationMailCode: null,
      userData: {},
      Docs: [
        {
          icon: 'icon-id',
          type: "Carte d'identité",
          text: this.$i18n.t('profile.docs.identity'),
          file: null,
          typeDescription: 'IdentityCard',
          typeCode: 'CIDT',
        },
        {
          icon: 'icon-mariage',
          type: "Acte d'état civil",
          text: this.$i18n.t('profile.docs.status'),
          file: null,
          typeDescription: 'ActeCivil',
          typeCode: 'AECV',
        },
        {
          icon: 'icon-diploma',
          type: 'Diplome',
          text: this.$i18n.t('profile.docs.diplomas'),
          file: null,
          typeDescription: 'Diploma',
          typeCode: 'DPLM',
        },
        {
          icon: 'icon-driving_licence',
          type: 'Permis de conduire',
          text: this.$i18n.t('profile.docs.drive'),
          file: null,
          typeDescription: 'DriverLicence',
          typeCode: 'PECO',
        },
        {
          icon: 'icon-birth_certificate',
          type: 'Acte de naissance',
          text: this.$i18n.t('profile.docs.birth'),
          file: null,
          typeDescription: 'BirthAct',
          typeCode: 'ACNS',
        },
        {
          icon: 'icon-passport',
          type: 'Passeport',
          text: this.$i18n.t('profile.docs.passport'),
          file: null,
          typeDescription: 'Passeport',
          typeCode: 'PSPR',
        },
        {
          icon: 'icon-testament',
          type: 'Testament',
          text: this.$i18n.t('profile.docs.testament'),
          file: null,
          typeDescription: 'Testament',
          typeCode: 'TSMT',
        },
        {
          icon: 'icon-criminal_record',
          type: 'Extrait de casier judiciaire',
          text: this.$i18n.t('profile.docs.record'),
          file: null,
          typeDescription: 'CasierJudiciaire',
          typeCode: 'ECJD',
        },
      ],
      Status: {
        canSelectAgreement: undefined,
        hasBPE: undefined,
      },
      AdminFolderId: null,
      emailDomainDialogOpened: false,
    };
  },
  computed: {
    ...mapGetters({
      asideMenusIsOpened: 'application/menusAreOpened',
      user: 'user/user',
      adminDocs: 'user/adminDocs',
      services: 'user/services',
      adminDocid: 'user/adminDocid',
      petalCounter: 'user/petalCounter',
      dematStatus: 'user/dematStatus',
    }),
    ...mapState({
      all: state => state.documents.all,
      isMIPIH: state => state.auth.isMIPIH,
    }),
    strength() {
      if (this.score === 0 && this.password.newPassword.length > 0) {
        return this.$i18n.t('strength.veryWeak');
      }
      if (this.score === 1) {
        return this.$i18n.t('strength.weak');
      }
      if (this.score === 2) {
        return this.$i18n.t('strength.soSo');
      }
      if (this.score === 3) {
        return this.$i18n.t('strength.good');
      }
      if (this.score === 4) {
        return this.$i18n.t('strength.great');
      }
      return '&nbsp;';
    },
    classObject() {
      return {
        'el-text--red': this.score === 0 || this.score === 1,
        'el-text--orange': this.score === 2,
        'el-text--green': this.score === 3 || this.score === 4,
        'mr-2': true,
      };
    },
  },

  methods: {
    ...mapActions({
      getUserInfo: 'user/getUserInfo',
      getIdentityDocs: 'user/getIdentityDocs',
      update: 'user/update',
      updatePassword: 'user/updatePassword',
      getServices: 'user/getServices',
      updateService: 'user/updateService',
      deleteService: 'user/deleteService',
      addService: 'user/addService',
      enablePaySlipDemat: 'user/enablePaySlipDemat',
      disablePaySlipDemat: 'user/disablePaySlipDemat',
      retrieveSignatureCode: 'user/retrieveSignatureCode',
      fetchFilesAndFolders: 'documents/all',
      setAdminDocId: 'user/setAdminDocId',
      setPetalsCounter: 'user/setPetalsCounter',
      setDematStatus: 'user/setDematStatus',
      deleteMyFile: 'documents/deleteMyFile',
      create: 'documents/create',
      exportSafebox: 'user/exportSafebox',
      updateEmail: 'user/updateEmail',
      storeLoadFCAuthSub: 'user/loadFCAuthSub',
      storeDisableFranceConnectLink: 'user/disableFranceConnectLink',
      storeSendEmailValidationCode: 'user/sendEmailValidationCode',
      storeVerifyEmailValidationCode: 'user/verifyEmailValidationCode',
    }),

    getTooltipText() {
      let tooltipText = '';
      if (this.feedback.warning) {
        tooltipText += this.$i18n.t(this.feedback.warning.replace(/[" ]/g, '_', ''));
      }
      for (let i = 0; i < this.feedback.suggestions.length; i += 1) {
        tooltipText += ' ';
        tooltipText += this.$i18n.t(this.feedback.suggestions[i].replace(/[" ]/g, '_', ''));
      }
      return tooltipText;
    },
    showScore(score) {
      this.score = score;
    },
    showFeedback({ suggestions, warning }) {
      this.feedback.warning = warning;
      this.feedback.suggestions = suggestions;
    },
    forgotten() {
      this.selected = this.options[1].text;
      this.$nextTick(() => {
        this.$refs.Code.toggle();
      });
    },
    CheckinDocs(doc) {
      this.$router.push({
        name: 'profile.details',
        params: { item: doc },
      });
    },
    activate() {
      if (this.Signature !== '') {
        this.enablePaySlipDemat(this.Signature).then(() => {
          this.setDematStatus().then(() => {
            this.Status = this.dematStatus;
            this.Signature = '';
          });
        });
      }
    },
    desactivate() {
      if (this.Signature !== '') {
        this.disablePaySlipDemat(this.Signature).then(() => {
          this.setDematStatus().then(() => {
            this.Status = this.dematStatus;
            this.Signature = '';
          });
        });
      }
    },
    handleFileUpload(typeDescription, typeCode) {
      for (let i = 0; i < this.$refs.file.length; i += 1) {
        if (this.$refs.file[i].value !== '') {
          //  console.log(this.$refs.file[i].value.split("\\").pop());
          this.file = this.$refs.file[i];

          this.submitFile(typeDescription, typeCode, this.$refs.file[i].files[0]);
        }
      }

      // this.file = this.$refs.file.file;
    },
    cancelInfo() {
      this.$refs.info.toggle();
    },
    cancelPass() {
      this.$refs.panelPass.toggle();
    },
    submitFile(description, code, file) {
      const content = {
        json: {
          sessionId: this.$store.state.auth.session.token,
          description: file.name.split('\\').pop(), // f.value.replace(/\.[^/.]+$/, ''),
          folderId: this.adminDocid,
          extension: file.name.split('.').pop(),
          typeCode: code,
          typeDescription: description,
          isUserProfileDoc: true,
        },
        fileContent: file,
      };
      this.create(content)
        .then(() => this.getIdentityDocs())
        .then(() => {
          for (let i = 0; i < this.adminDocs.length; i += 1) {
            for (let j = 0; j < this.Docs.length; j += 1) {
              // eslint-disable-next-line max-len
              if (this.Docs[j].typeCode.toLowerCase().trim() === this.adminDocs[i].typeCode.toLowerCase().trim()) {
                this.Docs[j].file = this.adminDocs[i];
              }
            }
          }
        })
        .then(() => this.fetchFilesAndFolders());
    },

    // method used to toggle the modal
    showModal() {
      this.modalVisible = !this.modalVisible;
      this.verifPass = '';
    },
    reveal(index) {
      this.$set(this.revealState, index, !this.revealState[index]);
    },
    deleteFile(fileId, index) {
      this.deleteMyFile(fileId)
        .then(() => this.getIdentityDocs())
        .then(() => {
          this.Docs[index].file = null;
        });
    },
    show(index) {
      this.showState = Array(this.services.length).fill(false);
      this.$set(this.showState, index, true);
    },
    hide(index) {
      this.$set(this.showState, index, false);
    },
    retrieveCode() {
      if (this.mobile > 0) {
        const o = { phone: this.mobile };
        this.$refs.Code.toggle();
        this.retrieveSignatureCode(o);
        this.mobile = null;
      }
    },

    noCustomValidityMessage(event) {
      event.target.setCustomValidity('');
    },
    validatePasswordForm() {
      // eslint-disable-next-line no-console
      this.check();
      let valid = true;
      if (this.password.newPassword.length < 8) {
        this.$refs.newPassword.setCustomValidity(this.$i18n.t('activate.indication.psswd'));
        valid = false;
      } else if (this.score <= 1) {
        this.$refs.newPassword.setCustomValidity(this.$i18n.t('profil.password.update.scoreTooLow'));
        valid = false;
      } else {
        this.$refs.newPassword.setCustomValidity('');
      }

      if (this.match === false) {
        this.$refs.passConf.setCustomValidity(this.$i18n.t('profil.password.update.missmatch'));
        valid = false;
      } else {
        this.$refs.passConf.setCustomValidity('');
      }
      return valid;
    },
    updatePass() {
      if (this.validatePasswordForm()) {
        this.$refs.panelPass.toggle();
        const newp = this.password.newPassword;
        const actual = this.$refs.actualPassword[0].value;
        this.updatePassword({ newp, actual });
        this.password.newPassword = '';
        this.password.similar = '';
        this.$refs.actualPassword[0].value = '';
      }
    },
    updateSer(id, index) {
      this.hide(index);
      const siteName = this.$refs.siteName[0].value;
      const login = this.$refs.login[0].value;
      const password = this.$refs.password[0].value;
      const o = {
        id,
        siteName,
        login,
        password,
      };
      this.updateService(o).then(() => {
        this.getServices();
      });
    },
    saveServ() {
      if (this.site === null || this.log === null || this.pass === null) {
        this.warn = true;
      } else {
        this.add = true;
        const o = {
          siteName: this.site,
          login: this.log,
          password: this.pass,
        };
        //  console.log(o);
        this.addService(o).then(() => {
          trackingServices.passwordStocked();
          this.getServices();
        });
        this.log = null;
        this.pass = null;
        this.site = null;
      }
    },
    deleteSer(id, index) {
      this.hide(index);

      const o = {
        id,
      };
      this.deleteService(o).then(() => {
        this.getServices();
      });
      this.revealState = Array(this.services.length).fill(false);
    },

    efolia() {
      window.open('https://portail.efolia.fr', '_blank');
    },
    check() {
      this.match = this.password.similar === this.password.newPassword;
    },

    verifyEmailValidationCode() {
      this.storeVerifyEmailValidationCode(
        { userId: this.userData.id, code: this.verificationMailCode.replace(/ /g, '') },
      ).then(() => {
        this.mode.validationEmailMode = false;
        this.mode.validationPasswordMode = true;
      });
    },

    isEmailDomainPopular() {
      const domain = this.userData.email.substr(this.userData.email.indexOf('@') + 1);
      if (!popularEmailDomains.find((item => item === domain))) {
        this.emailDomainDialogOpened = true;
        return false;
      }
      return true;
    },

    sendEmailValidationCode() {
      this.storeSendEmailValidationCode(
        { userId: this.userData.id, email: this.userData.email },
      ).then(() => {
        this.mode.validationEmailMode = true;
        this.showModal();
      });
    },

    updateUserInfo(passverified) {
      if (this.verifEmail !== this.userData.email && passverified !== true) {
        if (this.isEmailDomainPopular()) {
          this.sendEmailValidationCode();
        }
      } else {
        this.verifEmail = this.userData.email;
        const profile = {
          firstname: this.userData.firstname,
          lastname: this.userData.lastname,
          address: this.userData.address,
          zipCode: this.userData.zipCode,
          town: this.userData.town,
          phone: this.userData.phone,
        };
        this.update(profile).then(() => {
          this.getUserInfo();
        });
      }
    },

    // method used for checking the user password in order to update the email
    verifyPassword() {
      const payload = {
        email: this.userData.email,
        password: this.verifPass,
      };
      this.updateEmail(payload).then(() => {
        this.showModal();
        this.updateUserInfo(true);
      });
    },

    adminDocTreatment() {
      this.getIdentityDocs().then(() => {
        for (let i = 0; i < this.adminDocs.length; i += 1) {
          for (let j = 0; j < this.Docs.length; j += 1) {
            // eslint-disable-next-line max-len
            if (this.Docs[j].typeCode.toLowerCase().trim() === this.adminDocs[i].typeCode.toLowerCase().trim()) {
              this.Docs[j].file = this.adminDocs[i];
            }
          }
        }
      });
    },

    redirectToFranceConnectAuth() {
      auth.getConf().then((response) => {
        let fcUrl = `${response.CONF.FRANCE_CONNECT_HOST}/api/v1/authorize?`;
        fcUrl += `client_id=${response.CONF.FRANCE_CONNECT_CLIENT_ID}`;
        fcUrl += `&redirect_uri=${window.location.origin}/profile/fc-callback`;
        fcUrl += `&response_type=code&scope=openid&state=${uuid.v4()}&nonce=${this.user.id.substr(0, 10)}`;
        fcUrl += '&acr_values=eidas1';
        document.location = fcUrl;
      });
    },
    handleFranceConnectClb() {
      this.franceConnectClbDatas = {
        code: this.$route.query.code,
        redirectUri: `${window.location.origin}/profile/fc-callback`,
      };
      this.storeLoadFCAuthSub(this.franceConnectClbDatas)
        .then(() => {
          this.franceConnectClbDatas.subRegistered = true;
          this.userData.isLinkedToFranceConnect = true;
        })
        .catch(() => {
          this.franceConnectClbDatas.subRegistered = false;
        });
    },
    disableFranceConnectLink() {
      this.storeDisableFranceConnectLink().then(() => {
        this.userData.isLinkedToFranceConnect = false;
      });
    },
  },

  async mounted() {
    this.selected = this.options[0].text;
    this.fetchFilesAndFolders().then(() => {
      const administrativeFolder = this.all.find(x => x.code === 'administrative');
      this.setAdminDocId({ content: administrativeFolder ? administrativeFolder.folderId : '' }).then(() => {
        this.adminDocTreatment();
      });
    });

    if (!this.user) {
      await this.getUserInfo();
    }

    this.Info = this.Info.map((info) => {
      const newInfo = info;
      if (info.type === 'firstname' || info.type === 'lastname') {
        newInfo.readonly = this.user.isLinkedToFranceConnect;
      }
      return newInfo;
    });

    if (!this.services.length) {
      this.getServices();
    }
    if (!this.petalCounter) {
      this.setPetalsCounter();
    }
    this.revealState = Array(this.services.length).fill(false);
    this.showState = Array(this.services.length).fill(false);
    if (this.dematStatus) {
      this.Status = this.dematStatus;
      this.options[2].show = this.Status.canSelectAgreement;
    } else {
      this.setDematStatus().then(() => {
        this.Status = this.dematStatus;
        this.options[2].show = this.Status.canSelectAgreement;
      });
    }
    auth.getConf().then((response) => {
      if (response.CONF.MIPIH) {
        this.options.pop();
      }
    });
    // data used for updating user profile
    this.verifEmail = this.user.email;
    this.userData = { ...this.user };

    if (this.isFcCallback) {
      this.handleFranceConnectClb();
    }
  },
};
</script>
