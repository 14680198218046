<template>
  <div class="ep-dialog">
    <div class="ep-dialog--container -fit">
      <div class="close-icon" @click="$emit('close')">x</div>
      <div class="m-5">
        <i18n path="profile.popin.email.domain">
          <template #br>
            <br />
            <br />
          </template>
          <template #email>
            {{ mail }}
          </template>
        </i18n>
        <div class="flex mt-5">
          <button
            class="ep-button ep-button__invert"
            @click="$emit('close')">
            {{ $t('profile.popin.email.domain.button.update')}}
          </button>
          <button
            class="ep-button"
            @click="$emit('sendValidationCode')">
            {{ $t('profile.popin.email.domain.button.continue')}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mail: { type: String, required: true },
  },
};
</script>
